import React from "react"
import styled from "@emotion/styled"
import FocusLock from "react-focus-lock"
import { bool } from "prop-types"

const Modal = ({ show, children }) => {
  return (
    <FocusLock disabled={!show}>
      <ModalContainer show={show}>{children}</ModalContainer>
    </FocusLock>
  )
}

Modal.propTypes = {
  show: bool.isRequired,
}

export default Modal

const ModalContainer = styled.div`
  width: 100%;
  background: ${props => (props.darkMode ? "#000" : "#ffffff")};

  position: fixed;
  bottom: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 30px 40px 20px 40px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  z-index: 32;
  transform: ${props => (props.show ? "translateY(0%)" : "translateY(101%)")};
  transition: transform 0.3s ease-in-out;

  @media (min-width: 600px) {
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: fit-content;
    height: fit-content;
    height: -moz-fit-content;
    border-radius: 8px;
    display: ${props => (props.show ? "show" : "none")};
  }

  h1 {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;
    max-width: 240px;

    span {
      color: #5558ea;
      display: block;
      margin-top: 5px;
    }
  }

  p {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #70669b;
    margin: 5px 0 30px 0;
  }
`
