import React from "react"
import styled from "@emotion/styled"

const LinkButton = ({ text, type, disabled, onClick }) => {
  return (
    <Button type={type} disabled={disabled} onClick={onClick}>
      {text}
    </Button>
  )
}
export default LinkButton

const Button = styled.button`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  background: none;
  border: 0;
  border-radius: 20px;
  color: #01cc86;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
