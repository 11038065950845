import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"
import { bool } from "prop-types"

const Overlay = ({ show }) => {
  return <OverlayContainer show={show} />
}

Overlay.propTypes = {
  show: bool.isRequired,
}

export default Overlay

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const OverlayContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 31;
  display: ${props => (props.show ? "block" : "none")};
  animation: ${fadeIn} 0.3s ease-out;

  @media (min-width: 600px) {
    animation: ${fadeIn} 0.1s ease-out;
  }
`
