import React from "react"
import styled from "@emotion/styled"
import { FieldLabel } from "../fields"
import CalculateTextBox from "../CalculateTextBox/CalculateTextBox"
import { fieldNames } from "../../../../utils/enums"
import PropTypes from "prop-types"

const OneTimePin = ({ phone }) => {
  return (
    <FieldContainer>
      <FieldLabel
        labelFor={fieldNames.PIN}
        title={`Engångskod${phone}`}
        descText=""
        align="center"
      />

      <CalculateTextBox
        fieldName={fieldNames.PIN}
        // autoComplete="one-time-code"
        id="single-factor-code-text-field"
        placeHolder="Kod"
        maxLength="8"
        minLength="8"
        fieldType="tel"
      />
    </FieldContainer>
  )
}
export default OneTimePin

const FieldContainer = styled.div`
  max-width: 206px;
`

OneTimePin.defaultProps = {
  phone: ``,
}

OneTimePin.propTypes = {
  phone: PropTypes.string,
}
