import React from "react"
import styled from "@emotion/styled"

const OutlinedButton = ({ text, type, disabled, onClick }) => {
  return (
    <Button type={type} disabled={disabled} onClick={onClick}>
      {text}
    </Button>
  )
}
export default OutlinedButton

const Button = styled.button`
  border: 0;
  border-radius: 8px;
  background: none;
  font-size: 11px;
  font-weight: 400px;
  color: #01cc86;
  border: 1px solid #01cc86;
  padding: 10px 20px;
  cursor: pointer;
  /* height: 37px; */

  :disabled {
    cursor: not-allowed;
  }
`
